<template>
<div>

  <!-- Loading. -->
  <transition name="loading">
    <div v-if="!page" class="loading">
      <Loader/>
    </div>
  </transition>

  <!-- Content. -->
  <div
    v-if="page"
    class="content projects"
    ref="projects"
    >
    <ProjectsHero :title="page.title.rendered" v-if="show"/>
    <ProjectsList :projects="this.page.acf.highlighted_projects" v-if="show"/>
    <ProjectsContact :contact="page.acf.contact" v-if="show"/>
  </div>

</div>
</template>

<script>
import FadeIn from '../mixins/FadeIn'
import MatchedRoute from '../mixins/MatchedRoute'
import Wordpress from '../mixins/Wordpress'
import ProjectsHero from '../components/ProjectsHero'
import ProjectsList from '../components/ProjectsList'
import ProjectsContact from '../components/ProjectsContact'
import Loader from '../components/Loader'
import { TweenLite } from 'gsap' // note due to an import bug tween max is not avalible
import { throttle } from 'lodash'

export default {
  mixins: [
    FadeIn,
    MatchedRoute,
    Wordpress
  ],
  data () {
    return {
      show: true,
      page: null,
      primaryNavActive: false,
      bgOffset: 1,
      eventListenerScroll: null,
      eventListenerResize: null,
      scene: null
    }
  },
  methods: {
    async fetchPage () {
      let page = null
      if (this.$route.query.preview) {
        page = await this.$wp.getPagePreviewByPath(this.$matchedRoute.pathResolved)
      } else {
        page = await this.$wp.getPageByPath(this.$matchedRoute.pathResolved)
      }
      if (!this.hasPreviousRoute && !this.$route.query.preview) { await this.$wp.delay(3000) }
      this.page = page

      // Fade Page Content In After Tick.
      this.$nextTick(() => this.fadePageContentIn())

      if (this.scene === null) {
        this.scene = this.$scrollmagic.scene({
          triggerElement: 'body',
          triggerHook: 0,
          duration: document.documentElement.scrollHeight,
          reverse: true
        })
        this.$nextTick(() => {
          this.$scrollmagic.addScene(
            this.scene.setTween(
              TweenLite.fromTo(this.$refs.projects, 30, {
                backgroundPositionY: '0%'
              }, {
                backgroundPositionY: `100%`
              })
            ) // .addIndicators() // this is a debug option for vue scroll magic
          )
          this.scene.duration('100%')
        })
      }
    },
    onScroll (evt) {
      try {
        this.scene.duration(document.body.scrollHeight - window.innerHeight)
      } catch (e) {
        console.warn('@catch ~expected warning not bug~', e)
      }
    }
  },
  created () {
    this.throttleOnScroll = throttle(this.onScroll.bind(this), 300)
  },
  mounted () {
    this.eventListenerScroll = this.$nextTick(() => {
      window.addEventListener('scroll', this.throttleOnScroll, { passive: true })
    })
    this.eventListenerResize = this.$nextTick(() => {
      window.addEventListener('resize', this.throttleOnScroll, { passive: true })
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.eventListenerScroll)
    window.removeEventListener('resize', this.eventListenerResize)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.fetchPage())
  },
  components: {
    ProjectsHero,
    ProjectsList,
    ProjectsContact,
    Loader
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/container";

.projects {
  background-image: url('../assets/projects/bg.svg');
  background-position: top left;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: 266.3px 2468px;
  @media (max-width: 800px) {
     background-image: none;
  }
}

.loading {
  @include loading;
}
@include loading-page-fade;
</style>
