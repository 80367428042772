<template>
  <div class="container">
    <h1 v-html="title" />
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";

.container {
  @extend %container;
  @extend %mobile-container;
  margin-top: 172px;
  margin-bottom: 90px;
  @media (max-width: 800px) {
    margin-top: 109px;
    margin-bottom: m-grid(1, #{px(10)});
  }
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: grid-pct(1);
  width: grid-pct(8);
  // font-size: 3rem;
  font-weight: normal;
  letter-spacing: -.01em;
  @include t2;
  @media (max-width: 800px) {
    @include m-t2;
    @include m-margin(0.5);
  }
}
</style>
