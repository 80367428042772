function resolveParams (path, params) {
  for (let param in params) {
    path = path.replace(`:${param}`, params[param])
  }

  return path
}

export default {
  computed: {
    $matchedRoute () {
      let route = this.$route.matched.find(route => {
        return route.instances['default']._uid === this._uid
      })

      if (route) {
        route.pathResolved = resolveParams(route.path, this.$route.params)
      }

      return route
    }
  }
}
