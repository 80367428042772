<template>
  <div class="container">
    <ul>
      <li v-for="project in projects" :key="project.id">
        <router-link
          class="image-link"
          v-aspect-ratio="9 / 16"
          :to="project.link_relative"
          :style="{
            'background-image': `url('${project.featured_media_url || ' ' }')`
          }"
        />
        <!-- <img
          v-if="project.featured_media_url"
          :src="project.featured_media_url"
        /> -->
        <!-- </router-link> -->
        <div class="content">
          <!-- <Categories
            class="categories"
            :categories="project.categories"
          /> -->
          <h2>
            <router-link class="text-link" :to="project.link_relative">
              <span v-html="project.title.rendered"/>
            </router-link>
          </h2>
          <!-- <router-link class="text-link" :to="project.link_relative"></router-link> -->
          <div class="excerpt" v-html="project.excerpt.rendered"/>
          <router-link
            class="button"
            :to="project.link_relative">
            View project
          </router-link>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// import Categories from './Categories'
import Wordpress from '../mixins/Wordpress'

export default {
  props: {
    projects: {
      default: () => { return [] },
      type: Array
    }
  },
  mixins: [
    Wordpress
  ],
  components: {
    // Categories
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/grid";
@import "../styles/buttons";
@import "../styles/text";
@import "../styles/theme";

.container {
  @extend %container;
  @extend %mobile-container;
  margin-bottom: 165px;
   @media (max-width: 800px) {
    /**
    * margin-bottom adds space to the last li to
    * provide the spacing between component and
    * the next.
    */
    margin-bottom: calc(96px - #{m-grid(0.5)});
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  &:nth-child(even) {
    flex-direction: row-reverse;
    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
}

li + li {
  margin-top: 110px;
  @media (max-width: 800px) {
    margin-top: 0px;
  }
}

.image-link {
  margin-left: grid-pct(.5);
  margin-right: grid-pct(.5);
  width: grid-pct(6);
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 32px;
  }
}

@include theme ('light') {
  .image-link  {
    @include theme-transition;
    background-color: $light-placeholder;
  }
}

@include theme ('dark') {
  .image-link  {
    @include theme-transition;
    background-color: $dark-placeholder;
  }
}

.content {
  width: grid-pct(4);
  @media (max-width: 800px) {
    @include m-margin(0.5);
  }
}

.categories {
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

h2 {
  margin-top: 0;
  margin-bottom: 24px;
  // font-size: 2.25rem;
  font-weight: normal;
  letter-spacing: -.01em;
  @include t3;
  @media (max-width: 800px) {
    @include m-t3;
    margin-bottom: 16px;
  }
}

.excerpt {
  margin-top: 0;
  margin-bottom: 32px;
  // font-size: 1.125rem;
  // line-height: 1.5;
  @include b2;
  @media (max-width: 800px) {
    @include m-b2;
    margin-bottom: 24px;
  }
}

.excerpt /deep/ {
  p {
    margin: 0;
    @include b2;
    @media (max-width: 800px) {
      @include m-b2;
    }
  }

  p + p {
    margin-top: 24px;
  }
}

.button {
  @extend %btn-outline;
  @media (max-width: 800px) {
    width: m-grid(3);
    @include m-b3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 62px;
  }
}

@include link-text-hover-underline('.text-link', 0px, inherit, inherit);
@media (max-width: 800px) {
  @include link-text-hover-underline('.text-link', 0px, inherit, inherit);
}

.text-link {
  color: white;
  transition: color 0.3s linear;
  margin-top: px(5);
}
</style>
