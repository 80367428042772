<template>
    <div :class="{
      'background': true,
      'loading': (animation ==='loading'),
    }">
      <svg class="desktop m-hide" height="660" viewBox="0 0 1100 660" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="load1" d="M550 220L550 440" stroke="currentColor"/>
        <path class="load1" d="M660 330L440 330" stroke="currentColor"/>
        <path class="load2" d="M330 0L330 220" stroke="currentColor"/>
        <path class="load2" d="M440 110L220 110" stroke="currentColor"/>
        <path class="load3" d="M330 440L330 660" stroke="currentColor"/>
        <path class="load3" d="M440 550L220 550" stroke="currentColor"/>
        <path class="load4" d="M770 0L770 220" stroke="currentColor"/>
        <path class="load4" d="M880 110L660 110" stroke="currentColor"/>
        <path class="load5" d="M770 440L770 660" stroke="currentColor"/>
        <path class="load5" d="M880 550L660 550" stroke="currentColor"/>
        <path class="load6" d="M110 220L110 440" stroke="currentColor"/>
        <path class="load6" d="M220 330L5.72205e-06 330" stroke="currentColor"/>
        <path class="load7" d="M990 220L990 440" stroke="currentColor"/>
        <path class="load7" d="M1100 330L880 330" stroke="currentColor"/>
      </svg>
      <svg class="mobile" viewBox="0 0 375 625" fill="none" xmlns="http://www.w3.org/2000/svg">
        <!-- <rect width="375" height="625" fill="#C4C4C4"/> -->
        <path class="load1" d="M125 313L250 313" stroke="currentColor"/>
        <path class="load1" d="M188 250L188 375" stroke="currentColor"/>
        <path class="load2" d="M0 438L125 438" stroke="currentColor"/>
        <path class="load2" d="M62 375L62 500" stroke="currentColor"/>
        <path class="load3" d="M250 438L375 438" stroke="currentColor"/>
        <path class="load3" d="M313 375L313 500" stroke="currentColor"/>
        <path class="load4" d="M0 188L125 188" stroke="currentColor"/>
        <path class="load4" d="M62 125L62 250" stroke="currentColor"/>
        <path class="load5" d="M250 188L375 188" stroke="currentColor"/>
        <path class="load5" d="M313 125L313 250" stroke="currentColor"/>
        <path class="load6" d="M125 563L250 563" stroke="currentColor"/>
        <path class="load6" d="M188 500L188 625" stroke="currentColor"/>
        <path class="load7" d="M125 63L250 63" stroke="currentColor"/>
        <path class="load7" d="M187.5 0L187.5 125" stroke="currentColor"/>
      </svg>

    </div>
</template>
<script>
export default {
  props: {
    animation: {
      type: String,
      default: 'none',
      validator: function (value) {
        return ['none', 'loading'].indexOf(value) !== -1
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./../styles/theme";
@import "./../styles/container";
@import "./../styles/grid";
@include themed-stroke-colour;
.background {
  @extend %container;
  @extend %mobile-container;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  height: 100vh;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  .desktop {
    // margin-top: d-grid(1);
    // margin-top: calc(#{} - 220px);
    width: d-grid(10);
  }
  .mobile {
    display: none;
    @media (max-width: 800px) {
      width: m-grid(6);
      height: auto;
      display: block;
    }
  }
}

@include theme('light') {
  path {
    @include theme-transition;
    stroke: black;
    opacity: 0.1;
  }
}
@include theme('dark') {
  path {
    @include theme-transition;
    stroke: white;
    opacity: 0.25;
  }
}

.loading {
  path {opacity: 1;}
  .load1 {  animation: flash 1.5s infinite alternate; animation-delay: 0.75s; }
  .load2 {  animation: flash 1.2s infinite alternate; animation-delay: 0.25s; }
  .load3 {  animation: flash 1s infinite alternate; animation-delay: 1.1s; }
  .load4 {  animation: flash 1.3s infinite alternate; animation-delay: 0.30s; }
  .load5 {  animation: flash 1s infinite alternate; animation-delay: 1.50s; }
  .load6 {  animation: flash 2s infinite alternate; animation-delay: 0.15s; }
  .load7 {  animation: flash 1.40s infinite alternate; animation-delay: 1s; }
}

@keyframes flash {
  to { opacity: 0; }
}
</style>
