import { TweenLite } from 'gsap'

export default {
  
  methods: {

    fadePageContentIn () {
      const contentDiv = document.querySelector('.content')
      if (contentDiv) {
        const delay = 2
        TweenLite.fromTo(contentDiv, 1, { opacity: 0 }, { opacity: 1 }).delay(delay)
      }
    }
  }
}
