<template>
  <div class="container">
    <h2>{{ contact.heading }}</h2>
    <img
      v-if="contact.image"
      :src="contact.image.url"
      :alt="contact.image.alt"
      v-aspect-ratio
    />
    <div class="content">
      <p>{{ contact.description }}</p>
      <address v-html="contact.contact_text"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contact: Object
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";

.container {
  @extend %container;
  @extend %mobile-container;
  /* margin-bottom: 220px; */
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    /* margin-bottom: 115px; */
    flex-direction: column;
  }
}

h2 {
  align-self: flex-start;
  margin-top: 45px;
  margin-bottom: 0;
  margin-left: grid-pct(1);
  width: grid-pct(5);
  // font-size: 2.25rem;
  font-weight: normal;
  letter-spacing: -.01em;
  @include t3;
  @media (max-width: 800px) {
    @include m-t3;
    @include m-margin(0.5);
    margin-top: 0;
  }
}

img {
  position: relative;
  z-index: -1;
  margin-left: grid-pct(-4);
  width: grid-pct(4);
  @media (max-width: 800px) {
    width: m-grid(4);
    margin-left: m-grid(2);
    margin-top: -100px;
    margin-bottom: 32px;
  }
  @media (max-width: 420px) {
    margin-top: -120px;
  }
}

.content {
  margin-left: grid-pct(1);
  width: grid-pct(4);
  @include b1;
  @media (max-width: 800px) {
    @include m-b1;
    @include m-margin(0.5);
  }
}

p {
  margin-top: 0;
  margin-bottom: 24px;
  // font-size: 1.5rem;
  // line-height: 1.5;
  letter-spacing: -.01em;
  white-space: pre-line;
   @include b1;
    @media (max-width: 800px) {
      @include m-b1;
    }
}

address {
  font-style: normal;
}

address /deep/ {
  p {
    margin: 0;
    @include b2;
    @media (max-width: 800px) {
      @include m-b2;
      // margin-right: m-grid(1);
    }
  }

  p + p {
    margin-top: 24px;
  }

  a {
    @media (max-width: 800px) {
      display: inline-block;
    }
  }
}

@include link-text-hover-underline ('address /deep/ a', 1px);
@media (max-width: 800px) {
  @include link-text-hover-underline ('address /deep/ a', 0px);
}
</style>
