<template>
  <BgCross :animation="'loading'"/>
</template>
<script>
import BgCross from './BgCross'
export default {
  components: {
    BgCross
  }
}
</script>
<style lang="scss" scoped>
</style>
